
* {
  padding: 0;
  margin: 0;

}

body {
  /* font-family: var(--my-color) */
  /* font-family: 'Kaushan Script', cursive !important; */
  font-family: 'Raleway', sans-serif;
  font-family: 'Roboto', sans-serif;
}

 .image {
  height: 250px;
  padding: 20px;
  background: #fff;
  margin: auto;
}
/* .iconState{
  display: none;
} */
.state{
  display: none !important;
}
.ui.grid.container {
  margin-top: 45px;
}

.ui.grid > .row {
  background: #fff;
}
.ui.grid > .row > .column.lp {
  padding: 20px 40px 20px 20px;
  align-self: flex-start !important;
}
.ui.grid > .row > .column.rp {
  padding: 20px 20px 20px 40px;
  text-align: left;
  align-self: flex-start !important;
}

.ui.grid > .row > .column > img,
.ui.grid > .row > img {
  height: 100%;
}
.ui.placeholder .header:not(:first-child):before,
.ui.placeholder .image:not(:first-child):before,
.ui.placeholder .paragraph:not(:first-child):before {
  display: none;
}
.statusButtonPr{
  display: none !important;
}
.ui.label,
.ui.labels .label {
  font-size: 22px;
}

.column.rp h1 {
  color: #333;
}
.column.rp p {
  font-size: 18px;
  color: #777;
}
.ui.placeholder.segment .column .button,
.ui.placeholder.segment .column .field,
.ui.placeholder.segment .column textarea,
.ui.placeholder.segment .column > .ui.input {
  background-color: #ff3e6c;
  border: 1px solid #ff3e6c;
  color: #fff;
  font-size: 18px;
  margin-left: 0;
}
.Banner {
  height: 400px;
  position: relative;
}
.Banner .Media {
  background-color: white;
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: 300ms;
  cursor: pointer;
}
.Banner .Media .MediaCaption {
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0;
  padding: 15px;
  background-color: rgb(0, 12, 174);
  color: white;
  opacity: 0.6;
  width: 100%;
  height: 10%;
  font-size: 36px;
  font-weight: 200;
  transition: 300ms;
  cursor: pointer;
}
.Banner .Media .MediaCaption:hover {
  opacity: 0.8;
}
.Banner .Media:hover {
  filter: brightness(115%);
}
.Banner .BannerGrid {
  height: 100%;
  position: relative;
}
.Banner .Content {
  color: white;
  background-color: rgb(0, 48, 77);
  height: 100%;
  position: relative;
  cursor: pointer;
  padding: 30px;
  transition: 300ms;
}
.Banner .Content:hover,
.Banner .Content:active {
  background-color: tomato;
}
.Banner .Content:hover .ViewButton,
.Banner .Content:active .ViewButton {
  background-color: white;
  color: red;
}
.Banner .Content .Title {
  font-size: 42px;
  font-weight: 500;
}
.Banner .Content .Caption {
  margin-top: 10px;
  font-size: 24px;
}
.Banner .Content .ViewButton {
  margin-top: 40px;
  color: white;
  font-size: 24px;
  border: 3px solid white;
  text-transform: capitalize;
  transition: 200ms;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.icoFavorite{
  font-size: "10px !important"
}
.year-picker{
  width: 100% !important;
padding: 6px;
}